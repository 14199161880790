import { datadogRum } from '@datadog/browser-rum';
import { ReactNode, useContext, useEffect } from 'react';
import { FeatureFlagContext } from './FeatureFlagContext';

export interface AppContainerProps {
  children: ReactNode | ReactNode[];
}

export function AppContainer({ children }: AppContainerProps) {
  const { dataDogRumEnabled } = useContext(FeatureFlagContext);

  // Initialize DataDog RUM first time app runs
  useEffect(
    () => {
      if (dataDogRumEnabled) {
        console.error('dataDogRumEnabled: true');
        console.log('envFlag: ', process.env.NEXT_PUBLIC_ENV_FLAG);
        datadogRum.init({
          applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
          clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
          site: process.env.NEXT_PUBLIC_DATADOG_SITE,
          service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
          env: process.env.NEXT_PUBLIC_DATADOG_ENV,
          sampleRate: 100,
          sessionReplaySampleRate: 20,
          trackResources: true,
          trackLongTasks: true,
          trackInteractions: true,
          defaultPrivacyLevel: 'mask-user-input'
        });

        datadogRum.startSessionReplayRecording();
      } else {
        console.error('dataDogRumEnabled: false');
      }
    },
    // technically a dependency, but is an environment variable so should not change
    [dataDogRumEnabled]
  );

  return <>{children}</>;
}
